import React from "react";
import MyLink from "./MyLink";

interface Product {
  id: number;
  href: string;
  imageSrc: string;
  eventName: string;
}

interface Props {
  products: Product[];
}

export default function ProductRow({ products }: Props) {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-12 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        {/* <h2 id="products-heading" className="sr-only">
          Products
        </h2> */}

        <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:gap-x-8">
          {products.map((product) => (
            <MyLink
              key={product.id}
              href={product.href}
              className="group"
              linkType="outbound_link"
              eventName={product.eventName}
            >
              <div className="w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden sm:aspect-w-2 sm:aspect-h-3">
                <img
                  src={product.imageSrc}
                  // alt={product.imageAlt}
                  className="w-full h-full object-center object-cover group-hover:opacity-75"
                />
              </div>
              {/* <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900">
              <h3>{product.name}</h3>
              <p>{product.price}</p>
            </div>
            <p className="mt-1 text-sm italic text-gray-500">
              {product.description}
            </p> */}
            </MyLink>
          ))}
        </div>
      </div>
    </div>
  );
}
