import * as React from 'react';

import SEO from '../../components/seo';
import NavBar from '../../components/navBar';
import Footer from '../../components/Footer';
import ProductRow from '../../components/ProductRow';

// styles
const pageStyles = {
  color: '#232129',
  // padding: 96,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

const IndexPage = () => {
  return (
    <div style={pageStyles}>
      <SEO
        title="Reverse Streaks"
        description="This app is different than the typical Streaks you are used to. The idea behind it, is to keep track how you feel after you work on a project/hobby."
      />
      <NavBar />
      <div className="relative py-16 bg-white overflow-hidden">
        {/* <ContentSquares /> */}
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Reverse Streaks
              </span>
            </h1>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              This app is different than the typical Streaks you are used to. The idea behind it, is to keep track how
              you <strong>feel after</strong> you work on a project/hobby.
            </p>
          </div>
          <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
            <p>
              Let’s say you have a new goal of getting into jogging. Each time you go jogging, you mark how you feel
              after. But, if you are unmotivated or hate it a few times in a row, it’s time to rethink whether or not
              you should keep doing it. We think life is too short to spend time on things you don’t enjoy. So figure
              out a way to make jogging more enjoyable or look into other hobbies for exercise that you would enjoy.
            </p>
            <p>
              For someone who finds themselves constantly exploring new projects, this is a good way to gauge how you
              feel overtime. We build a lot of apps that sound great when we first think of them. But the motivation
              fades over time and it’s good to revaluate or move onto something better.
            </p>
            <p>
              In settings, you can customize your options to see what feels right for you. We’ve felt that after 5 times
              in a row of disliking a project, we move on.
            </p>
          </div>
        </div>
      </div>
      <ProductRow products={products} />
      <Footer />
    </div>
  );
};

export default IndexPage;

const products = [
  {
    id: 1,
    href: 'https://apps.apple.com/us/app/motivation-streaks/id1617505203',
    imageSrc:
      'https://is1-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/a6/a1/28/a6a128cc-9e9f-afa2-6895-001eff9e2489/921c392a-5915-4cc5-a297-9686d0f1cab0_1.png/920x0w.webp',
    eventName: 'image_1_motivation_streaks_clicked',
  },
  {
    id: 2,
    href: 'https://apps.apple.com/us/app/motivation-streaks/id1617505203',
    imageSrc:
      'https://is4-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/41/8b/5c/418b5c12-d772-7e00-5e6d-8239b1880b8b/99c673fe-b642-4dba-9717-42a90703ddf8_2.png/920x0w.webp',
    eventName: 'image_2_motivation_streaks_clicked',
  },
  {
    id: 3,
    href: 'https://apps.apple.com/us/app/motivation-streaks/id1617505203',
    imageSrc:
      'https://is5-ssl.mzstatic.com/image/thumb/PurpleSource122/v4/76/d1/d5/76d1d597-ee59-64b9-1917-b06d985e9355/3d633cee-f209-4926-9ca2-86234391c770_4.png/920x0w.webp',
    eventName: 'image_3_motivation_streaks_clicked',
  },
  // {
  //   id: 4,
  //   href: "https://apps.apple.com/us/app/motivation-streaks/id1617505203",
  //   imageSrc:
  //     "https://is4-ssl.mzstatic.com/image/thumb/PurpleSource122/v4/d5/20/c1/d520c1e1-1d7e-36b8-cc79-55f80fc42a4f/4320aba4-bb04-4f7a-b72d-7b726938f983_3.png/920x0w.webp",
  // },
];
